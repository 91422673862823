/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/styles/normalize.css';
import './src/styles/reset.css';
import './src/styles/fonts.css';
import './src/styles/global.css';